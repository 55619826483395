import React, { Fragment } from 'react'

import {
  inspired,
  container,
  contentView,
  columns, 
  column,
  visionBox
} from './styles/EstudeAqui.styles'

import {
  btn4,
  contentNew
} from '../components/styles/InternalPages.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
      <div css={container}>
        <div css={visionBox}>
          <div css={columns}>
            <div css={column}>
              <div css={[contentView, contentNew]}>
                <h4 style={{ marginTop: 18 }}>POCKET AGRO</h4>
                <p style={{ marginBottom: 16, fontSize: 14 }}>Olá, estamos felizes em te receber aqui!</p>
                <p style={{ marginBottom: 16, fontSize: 14 }}>O Agro é a atividade produtiva mais antiga e rica da sociedade brasileira, responsável hoje por cerca de 1/5 do PIB e por metade das exportações. Vem se modernizando em ritmo acelerado e ininterrupto, e é um campo repleto de oportunidades de investimentos, desenvolvimento e geração de empregos.</p>
                <p style={{ marginBottom: 16, fontSize: 14 }}>O Agro é definitivamente “tech”, visto que o país é referência mundial em ciência, tecnologia e produtividade, e pode-se dizer que é de certa forma “tudo”, já que está presente em quase tudo o que consumimos. O setor, no entanto, está longe de ser “pop” e convive com desconfianças e preconceitos muitas vezes desprovidos de qualquer embasamento técnico.</p>
                <p style={{ marginBottom: 16, fontSize: 14 }}>A Olimpíada Pocket busca contribuir com a agenda de educação sobre o Agro, e convida os seus participantes a conhecer e explorar os desafios, oportunidades e tendências de um setor bastante relevante para o país e para isto convidaremos os participantes que mais se destacarem na Olimpíada para participar de um <span style={{ fontSize: 14 }}>Conselho Consultivo</span> que ajudará a desenhar a <span style={{ fontSize: 14 }}>Olimpíada Brasileira do Agribusiness</span> que será lançada em 2021.</p>
                <p style={{ marginBottom: 24, fontSize: 14 }}>Agora, é com você! Inscreva-se e nos ajude a escrever esta nova história!</p>
                <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=29&url_redirect=https%3A%2F%2Fapp.olimpiadapocket.com.br%2F' css={btn4} style={{ margin: '0 auto' }}>ACESSE A PROVA</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default InspiredSite
